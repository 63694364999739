import { ApiMananger } from "actions/rtkquery";
import {
  formulaireByIdEndepoint,
  serviceBasesEndepoint,
  serviceCommandesEndepoint,
} from "routes/api/endpoints";

export const serviceMutation = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    addServices: builder.mutation({
      query: (formData) => ({
        url: serviceCommandesEndepoint.ServicesCommandees(),
        method: "POST",
        body: formData,
      }),
    }),
    addFormResponse: builder.mutation({
      query: (formData) => ({
        url: serviceCommandesEndepoint.FormResponse(),
        method: "POST",
        body: formData,
      }),
    }),
    updateService: builder.mutation({
      query: ({ order_id, serviceData }) => ({
        url: serviceCommandesEndepoint.ServicesCommandeesById(order_id),
        method: "PATCH",
        body: serviceData,
      }),
    }),
    addDataFormSC: builder.mutation({
      query: (formData) => ({
        url: serviceBasesEndepoint.getServiceBases(),
        method: "POST",
        body: formData,
      }),
    }),
    addDataFormAbout: builder.mutation({
      query: (formData) => ({
        url: serviceBasesEndepoint.getServiceBases(),
        method: "POST",
        body: formData,
      }),
    }),
    getServices: builder.mutation({
      query: (body) => ({
        url: formulaireByIdEndepoint.getServices(),
        method: "POST", // Correct the typo here
        body: body,
      }),
    }),
  }),
});

export const {
  useGetServicesMutation,
  useAddServicesMutation,
  useAddFormResponseMutation,
  useUpdateServiceMutation,
  useAddDataFormSCMutation,
} = serviceMutation;
