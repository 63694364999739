import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Titre from "components/FormulairePreStay/Titre";
import Text from "components/FormulairePreStay/Text";
import { useSelector } from "react-redux";
import { useGetServicesForFormularQuery } from "actions/service/query";
import { CheckboxContainer } from "pages/Admin/GestionEnvoie/TabsFormPreStay/StyledTabsForm";
import { useFormContext } from "react-hook-form";

// Styled Components
const Container = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  padding-bottom: 25px;
  @media (max-width: 767px) {
    display: block;
  }
`;
const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
const LargeIcon = styled.img`
  width: 15%;
  border-radius: 50%;
`;
const Description = styled.div`
  display: flex;
  flex-direction: column;
`;
const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  justify-content: end;
`;
const PriceText = styled.div`
  font-size: 14px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
`;

const QuantityButton = styled.button`
  background-color: #d26129;
  color: white;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #b14e1f;
  }

  &:active {
    background-color: #9f3d10;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const QuantityDisplay = styled.span`
  font-size: 18px;
  font-weight: bold;
  margin: 0 10px;
`;

// ServicePayant Component
const ServicePayant = () => {
  const userId = useSelector((state) => state?.persist?.auth?.user_id);
  const { data: services } = useGetServicesForFormularQuery(userId);
  const [selectedServices, setSelectedServices] = useState([]);
  const { register, setValue, watch } = useFormContext();

  const voyageur = watch("voyageur");

  useEffect(() => {
    updateFormValue();
  }, [selectedServices, voyageur]);

  const handleSelect = (serviceId) => {
    setSelectedServices((prev) => {
      const existingService = prev.find(
        (service) => service.service_id === serviceId
      );
      if (existingService) {
        return prev.filter((service) => service.service_id !== serviceId);
      } else {
        return [
          ...prev,
          { service_id: serviceId, quantity: 1, status: "treat" },
        ];
      }
    });
  };

  const handleQuantityChange = (serviceId, increment) => {
    setSelectedServices((prev) =>
      prev.map((service) =>
        service.service_id === serviceId
          ? { ...service, quantity: Math.max(1, service.quantity + increment) }
          : service
      )
    );
  };

  const updateFormValue = () => {
    const formValue = {
      voyageur: voyageur || 0,
      services: selectedServices,
    };
    setValue("servicesPayants", formValue);
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <Container className="col-lg-8 col-sm-12 col-md-12 col-xl-6">
        <Titre
          text="Services Payants"
          style={{
            color: "#D26129",
            fontFamily: "Roboto",
            fontSize: "25.28px",
            fontWeight: "600",
          }}
        />
        {services?.data?.map((service) => {
          const selectedService = selectedServices.find(
            (s) => s.service_id === service.id
          );
          const isSelected = !!selectedService;

          return (
            <Content key={service?.id}>
              <Item>
                <LargeIcon src={service?.image_url} alt="Service" />
                <label htmlFor={`checkbox-${service?.id}`}>
                  <Description>
                    <Text
                      content={
                        <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                          {service.title}
                        </span>
                      }
                    />
                    <Text
                      content={
                        <span
                          style={{ fontSize: "14px" }}
                          dangerouslySetInnerHTML={{
                            __html: service.description,
                          }}
                        />
                      }
                    />
                  </Description>
                </label>
              </Item>
              <div>
                <PriceContainer>
                  <PriceText>
                    <label
                      htmlFor={`checkbox-${service?.id}`}
                      className="d-flex flex-column">
                      <Text
                        content={
                          <span
                            style={{ fontSize: "18px", fontWeight: "bold" }}>
                            {service?.prix_unitaire}
                            <span
                              style={{ fontWeight: "normal", color: "gray" }}>
                              {" "}
                              FCFA{" "}
                            </span>
                          </span>
                        }
                      />
                      <Text
                        content={
                          <span style={{ fontSize: "15px" }}>par personne</span>
                        }
                      />
                    </label>
                  </PriceText>
                  <CheckboxContainer>
                    <div className="round">
                      <input
                        {...register("servicesPayants")}
                        type="checkbox"
                        id={`checkbox-${service?.id}`}
                        checked={isSelected}
                        onChange={() => handleSelect(service.id)}
                      />
                      <label htmlFor={`checkbox-${service?.id}`}></label>
                    </div>
                  </CheckboxContainer>
                </PriceContainer>
                {isSelected && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}>
                    <QuantityButton
                      type="button"
                      onClick={() => handleQuantityChange(service.id, -1)}
                      disabled={selectedService.quantity === 1}>
                      -
                    </QuantityButton>
                    <QuantityDisplay>
                      {selectedService.quantity}
                    </QuantityDisplay>
                    <QuantityButton
                      type="button"
                      onClick={() => handleQuantityChange(service.id, 1)}>
                      +
                    </QuantityButton>
                  </div>
                )}
              </div>
            </Content>
          );
        })}
      </Container>
    </div>
  );
};

export default ServicePayant;
