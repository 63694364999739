import { ContainerDemandeClient } from "components/RelationClientDetail/DemandeClient/DemandeClientCSS";
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss";
import React, { useState } from "react";
import {
  Div,
} from "components/AdminContain/NouveauVoyageur/NouveauVoyageurCss";
import { useNavigate, useParams } from "react-router-dom";
import { GESTION_RESERVATION_PATH } from "routes/navigation/navigationPaths";
// import { useDispatch } from "react-redux";
import { useFormContext } from "react-hook-form";
import FormInfoReservation from "components/AdminContain/NouveauVoyageur/FormInfoReservation";
import "react-toastify/dist/ReactToastify.css";
import { Navig } from "../../GestionVoyageur/GestionVoyageurCss";
import VoyageurSelector from "components/AdminContain/NouveauVoyageur/VoyageurSelector";
import ActionButtons from "components/AdminContain/NouveauVoyageur/ActionButtons";
import FormInfoVoyageur from "components/AdminContain/NouveauVoyageur/FormInfoVoyageur";
import { useVoyageurReservation } from "hooks/useVoyageurReservation";
import { useRooms } from "hooks/useRooms";
import BreadCumbReservation from "./BreadCumbReservation";

export default function NouvelleReservation() {
  const { id } = useParams();
  const isUpdate = !!id;
  const navigate = useNavigate();
  const { handleSubmit } = useFormContext();
  const [loading, setLoading] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(null);

  const { handleSubmitVoyageurReservation, reservation } = useVoyageurReservation(id);
  const { optionsChambres } = useRooms();

  const handleButtonClick = (buttonType) => {
    setButtonClicked(buttonType);
  };

  const handleSubmitWithButtons = async () => {
    setLoading(true);
    try {
      await handleSubmitVoyageurReservation();
      if (buttonClicked === "saveAndExit") {
        setTimeout(() => {
          navigate(`/${ GESTION_RESERVATION_PATH}`);
        }, 1000);
      }
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setLoading(false);
    }
  };
  // const limitedVoyageurs = filteredVoyageurs.slice(0, 20);
  return (
    <div style={styledRelationClientDetail.relationClient}>
      <div style={{
        padding: "20px 40px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}>
        <BreadCumbReservation />
        <ContainerDemandeClient className="container-fluid" style={{
          backgroundColor: "white",
          borderRadius: "20px",
          padding: "40px",
        }}>
          <Navig>
            <p style={{
              color: "#000000",
              fontFamily: "Roboto",
              fontWeight: 400,
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: "5px",
            }}>
              <span style={{
                color: "#008DF3",
                fontFamily: "Roboto",
                fontWeight: "400",
              }}>
                {isUpdate && reservation ? "Modification voyageur" : "Nouveau voyageur"}
              </span>
              <span>/</span>
            </p>
          </Navig>
          <form onSubmit={handleSubmit(handleSubmitWithButtons)}>
            <div className="row">
              <div className="col-md-12 col-lg-7">
                <div className="row card border-1 border-thin pb-4 mb-5">
                  <Div className="col-5 col-lg-5">INFORMATIONS DU VOYAGEUR</Div>
                  {/* Add form fields for voyageur information */}
                  <FormInfoVoyageur/>
                </div>
                <div className="row card border-1 border-thin pb-5">
                  <Div className="col-6 col-lg-6">INFORMATIONS DE LA RESERVATION</Div>
                  {/* Add form fields for reservation information */}
                  <FormInfoReservation optionsChambres={optionsChambres} />
                </div>
              </div>
              <div className="col-md-12 col-lg-5">
                {!isUpdate && <VoyageurSelector />}
              </div>
            </div>
            <ActionButtons
              loading={loading}
              isUpdate={isUpdate}
              onButtonClick={handleButtonClick}
            />
          </form>
        </ContainerDemandeClient>
      </div>
    </div>
  );
}
