import { ApiMananger } from "actions/rtkquery";
import {
  formulaireByIdEndepoint,
  serviceBasesEndepoint,
  serviceCommandesEndepoint,
} from "routes/api/endpoints";

export const serviceQueries = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    getServiceBases: builder.query({
      query: (userId) => ({
        url: serviceBasesEndepoint.getServiceBases.replace(),
        method: "GET",
        params: { user: userId },
      }),
    }),
    getServicecommandes: builder.query({
      query: (userId) => ({
        url: serviceCommandesEndepoint.getserviceCommandes.replace(),
        method: "GET",
        params: { user: userId },
      }),
    }),
    getAllServices: builder.query({
      query: ({ hotelId }) => ({
        url: `${formulaireByIdEndepoint.getListeServices()}?hotelId=${hotelId}`,
        method: "GET",
      }),
    }),
    getServicesForFormular: builder.query({
      query: () => ({
        url: serviceCommandesEndepoint.getserviceCommandes,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetAllServicesQuery, useGetServicesForFormularQuery } =
  serviceQueries;
