import React from "react";
import styled from "styled-components";
import Logo from "components/Navbar/Logo";
import Language from "components/Navbar/Language";

const NavbarContainer = styled.div`
    width: 100%; 
    background: #D26129;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    position: fixed;
    z-index: 1;
`;

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Navbar = ({ logoSrc, lang, setLang, link }) => {
    return (
        <>
            <NavbarContainer>
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center flex-nowrap">
                    <LogoWrapper>
                    <Logo src={logoSrc} link={link}/>
                </LogoWrapper>
                <Language lang={lang} setLang={setLang} />
                    </div>
                </div>
            </NavbarContainer>
        </>
    );
};

export default Navbar;
