import React from "react"
import { BoutonFiltre } from "components/RelationClientDetail/DemandeClient/DemandeClientCSS"
import PropTypes from "prop-types";

export const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  return (
    <div className="pagination">
      <BoutonFiltre onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
        Précédent
      </BoutonFiltre>
      <div>
      {Array.from({ length: totalPages }, (_, index) => (
        <button
          key={index + 1}
          onClick={() => onPageChange(index + 1)}
          className={index + 1 === currentPage ? "active" : ""}
        >
          {index + 1}
        </button>
      ))}
      </div>
      <BoutonFiltre onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>
        Suivant
      </BoutonFiltre>
    </div>
  );
};

  Pagination.propTypes = {
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
  };