export const OptionVenez = [
  { id: 0, value: "", name: "Vous venez .." },
  { id: 1, value: "Seul", name: "Seul" },
  { id: 2, value: "En couple", name: "En couple" },
  { id: 3, value: "En famille", name: "En famille" },
  { id: 4, value: "En groupe d'amis", name: "En groupe d'amis" },
];

export const OptionReserve = [
  {
    id: 0,
    name: "Quelle est la raison de votre voyage ?",
    value: "Quelle est la raison de votre voyage ?",
  },
  { id: 1, name: "Visite familiale", value: "Visite familiale" },
  { id: 2, name: "Business", value: "Business" },
  { id: 3, name: "Voyage de noce", value: "Voyage de noce" },
  { id: 4, name: "Voyage touristique", value: "Voyage touristique" },
  { id: 5, name: "Autre", value: "Autre" },
];

export const OptionConnu = [
  { id: 0, value: "", name: "Comment nous avez-vous connus ?" },
  { id: 1, value: "bouche-a-oreille", name: "Bouche à oreille" },
  { id: 2, value: "facebook", name: "Facebook" },
  { id: 3, value: "instagram", name: "Instagram" },
  { id: 4, value: "presse", name: "Presse" },
  { id: 5, value: "influenceur", name: "Influenceur" },
  { id: 6, value: "google", name: "Google" },
  { id: 7, value: "tripAdvisor", name: "TripAdvisor" },
  {
    id: 8,
    value: "partenaire-de-reservation",
    name: "Partenaire de réservation : Booking, Expedia, Staycation",
  },
  { id: 9, value: "autre", name: "Autre" },
];
export const stateLink = [
  { id: 1, number: "01", texte: "À propos de vous" },
  { id: 2, number: "02", texte: "Votre séjour" },
  { id: 3, number: "03", texte: "Les services" },
  { id: 4, number: "04", texte: "Validation" },
];
