// import { useGetHotelQuery } from "actions/hotel/queries";

function useGetCurrentUser() {
  // const user = null;
  const user = { role: "user" };
  // const { data } = useGetHotelQuery();
  // console.log({ data });

  return user;
}

export default useGetCurrentUser;
