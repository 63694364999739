import { ApiMananger } from "actions/rtkquery";
import {
  voyageurEndpoint,
  voyageurReservationEndpoint,
} from "routes/api/endpoints";

export const voyageurMutation = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    addNewVoyageur: builder.mutation({
      query: (voyageurData) => ({
        url: voyageurEndpoint.addNewVoyageur(),
        method: "POST",
        body: voyageurData,
      }),
      invalidatesTags: ["voyageurReservation"],
    }),
    deleteVoyageur: builder.mutation({
      query: (id) => ({
        url: voyageurEndpoint.deleteVoyageur.replace(":id", id),
        method: "DELETE",
      }),
      invalidatesTags: ["voyageurReservation"],
    }),
    updateVoyageur: builder.mutation({
      query: ({ id, voyageurData }) => ({
        url: voyageurEndpoint.updateVoyageur.replace(":id", id),
        method: "PUT",
        body: voyageurData,
      }),
      invalidatesTags: ["voyageurReservation"],
    }),
    importerDonneesVoyageur: builder.mutation({
      query: (formData) => ({
        url: voyageurEndpoint.importerDonneesVoyageur,
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    }),
    addNewVoyageurReservation: builder.mutation({
      query: (voyageurReservationData) => ({
        url: voyageurReservationEndpoint.addNewVoyageurReservation(),
        method: "POST",
        body: voyageurReservationData,
      }),
      invalidatesTags: ["voyageurReservation"],
    }),
  }),
});

export const {
  useImporterDonneesVoyageurMutation,
  useUpdateVoyageurMutation,
  useDeleteVoyageurMutation,
  useAddNewVoyageurReservationMutation,
  useAddNewVoyageurMutation,
} = voyageurMutation;
