import React, { useState, useEffect } from "react";
import Navbar from "layouts/navbar/Navbar";
import Footer from "layouts/Footer/Footer";
import { Outlet } from "react-router-dom";
import logoSrc from "image/azalai.png";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function SplitShellForm() {
  const [lang, setLang] = useState("Français");
  const { i18n } = useTranslation();
  const { reservationId, hotelId, voyageurId } = useParams();

  // Met à jour la langue dans i18next lorsque `lang` change
  useEffect(() => {
    const currentLang = lang === "Français" ? "fr" : "en";
    i18n.changeLanguage(currentLang);
  }, [lang, i18n]);
  return (
    <div>
      <Navbar
        logoSrc={logoSrc}
        setLang={setLang}
        lang={lang}
        link={`/${reservationId}/hotel/${hotelId}/voyage/${voyageurId}`}
      />
      <div className="pb-md-2 pb-5"></div>
      <Outlet />
      <Footer />
    </div>
  );
}

export default SplitShellForm;
