import React, { useState, useEffect } from "react";
import CustomButton from "components/CustomComponent/Boutton/CustomButton";
import DrapeauSenegal from "image/DrapeauSenegal.jpg";
import InfoPersonnel from "components/RelationClientDetail/InfoClient/InfoPersonnel";
import { TfiMedall } from "react-icons/tfi";
import CustomSelect from "components/CustomComponent/Select/CustomSelect";
import badge from "image/carbon_badge.png";
import "./calendrier.css";
import { BsSortAlphaDown } from "react-icons/bs";
import { RiLogoutCircleRLine } from "react-icons/ri";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { PiCalendarBold } from "react-icons/pi";
import { useSearchParams } from "react-router-dom";
import {
  InputSelectDate,
  ContainSelectData,
  SpanIcon,
} from "components/RelationClientDetail/DemandeClient/DemandeClientCSS";
import { FormateName } from "utils/FormateName";
import { GrInbox } from "react-icons/gr";

const Loader = () => (
  <div className="loader-container">
    <div className="loader"></div>
  </div>
);

const CalendrierduClient = ({ reservations, formatDate, dateNow }) => {
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const filterDates = searchParams.get("dateDebute") || "";
  const [filterDate, setFilterDate] = useState(getCurrentDate());
  const [isSortedAlphabetically, setIsSortedAlphabetically] = useState(false);

  const [loading, setLoading] = useState(true); // State for loading

  useEffect(() => {
    // Simulate data fetching (you can replace this with your actual data fetching logic)
    setTimeout(() => {
      setLoading(false); // Set loading to false once data is fetched
    }, 2000);
  }, []);

  useEffect(() => {
    setFilterDate(getCurrentDate());
  }, [dateNow]);

  const handleSortAlphabetically = () => {
    setIsSortedAlphabetically(!isSortedAlphabetically);
  };
  const handleFilterChange = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set(key, value);
    } else {
      newSearchParams.delete(key);
    }
    setSearchParams(newSearchParams);
  };
  const filteredReservations = reservations.filter((reservation) => {
    if (!filterDate) return true;
    const dateDebutSejour = new Date(reservation.date_debut_sejour);
    return (
      dateDebutSejour.toISOString().split("T")[0] === filterDate &&
      (!filterDates ||
        reservation.date_debut_sejour
          .toLowerCase()
          .includes(filterDates.toLowerCase()))
    );
  });

  const calculateNuitées = (dateDebut, dateFin) => {
    const debutSejour = new Date(dateDebut);
    const finSejour = new Date(dateFin);
    const differenceMs = finSejour - debutSejour;
    const differenceJours = differenceMs / (1000 * 60 * 60 * 24);
    return differenceJours;
  };

  const options = [
    { value: "option1", label: "Arrivées du jour" },
    { value: "option2", label: "Présences du jour" },
    { value: "option3", label: "Départs du jour" },
  ];

  const handleChange = (selectedOption) => {
    console.log("Selected option:", selectedOption);
  };

  const reservationsArrivesSelectionne = filteredReservations.filter(
    (reservation) => {
      const dateDebutSejour = new Date(reservation.date_debut_sejour);
      return dateDebutSejour.toISOString().split("T")[0] === filterDate;
    }
  );

  const sortedReservationsArrivesSelectionne = [
    ...reservationsArrivesSelectionne,
  ];
  console.log(
    sortedReservationsArrivesSelectionne,
    "sortedReservationsArrivesSelectionne"
  );
  if (isSortedAlphabetically) {
    sortedReservationsArrivesSelectionne.sort((a, b) => {
      const nameA =
        `${a.voyageur_details.prenom} ${a.voyageur_details.nom}`.toLowerCase();
      const nameB =
        `${b.voyageur_details.prenom} ${b.voyageur_details.nom}`.toLowerCase();
      return nameA.localeCompare(nameB);
    });
  }

  const truncateEmail = (email) => {
    const visiblePart = email.slice(0, -7); // Garde tout sauf les 7 dernières lettres
    return visiblePart + "..."; // Ajoute des points à la fin pour indiquer la troncature
  };

  const generatePDF = () => {
    const doc = new JsPDF();
    const badgeImage = badge;

    // Titre
    doc.setFontSize(18);
    doc.text("Rapport des réservations", 14, 22);

    // Sous titre
    doc.setFontSize(12);
    doc.text(`Date: ${filterDate}`, 14, 32);

    doc.autoTable({
      startY: 40,
      head: [["Voyageur", "Arrivée", "Nuitée", "Info Pre-Stay", "Badges"]],
      body: sortedReservationsArrivesSelectionne.map((reservation) => [
        `${reservation.voyageur_details.prenom} ${reservation.voyageur_details.nom}`,
        formatDate(reservation.date_debut_sejour),
        calculateNuitées(
          reservation.date_debut_sejour,
          reservation.date_fin_sejour
        ),
        "Lorem ipsum dolor sit amet.",
        {
          content: "",
          styles: {
            cellWidth: 20,
            minCellHeight: 20,
            valign: "middle",
            halign: "center",
          },
        },
      ]),
      styles: {
        fontSize: 11,
        cellPadding: 4,
        textColor: [0, 0, 0],
        lineColor: [44, 62, 80],
        lineWidth: 0.1,
        valign: "middle",
        halign: "center",
      },
      didDrawCell: (data) => {
        if (data.column.index === 4 && data.cell.section === "body") {
          const centerX = data.cell.x + data.cell.width / 2 - 3;
          const centerY = data.cell.y + data.cell.height / 2 - 3;
          doc.addImage(badgeImage, "PNG", centerX, centerY, 6, 6);
        }
      },
    });

    doc.save("reservations_report.pdf");
  };
  const uniqueDates = [
    ...new Set(reservations.map((item) => item.date_debut_sejour)),
  ];

  return (
    <div className="overflow-y-scroll custom-scrollbar">
      <div
        className="row card d-flex flex-row justify-content-center"
        style={{ border: "none", borderRadius: "10px", overflow: "hidden" }}
      >
        <div
          className="card-header border-0"
          style={{
            backgroundColor: "#FFFFFF",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
          }}
        >
          <div
            className="row"
            style={{
              fontFamily: "roboto",
              fontSize: "15px",
              fontWeight: "400",
            }}
          >
            <div className="col-md-12 col-xl-12 d-flex  flex-md-row justify-content-between align-items-start align-items-md-center">
              <div className="d-md-flex d-block align-items-center gap-2">
                <p
                  className="m-0 p-0"
                  style={{
                    color: "#212529",
                    fontWeight: 500,
                    fontSize: "14px",
                    fontFamily: "Roboto",
                  }}
                >
                  Filtrer par
                </p>
                <CustomSelect
                  className="custom-select"
                  color="#000000"
                  border="2px solid #FFC857"
                  fontFamily="Roboto"
                  margin="10px"
                  fontSize="14px"
                  fontWeight="500"
                  borderRadius="40px"
                  options={options}
                  onChange={handleChange}
                />
              </div>
              <div className="d-md-flex gap-2 justify-content-center d-block align-items-center">
                <div
                  style={{
                    color: "#000000",
                    fontSize: "14px",
                    fontFamily: "Roboto",
                    fontWeight: 500,
                  }}
                  onClick={() => setFilterDate("")}
                >
                  Tri
                </div>
                <CustomButton
                  className="d-flex align-items-center gap-1"
                  padding={"5px"}
                  color="#000000"
                  backgroundColor="#FFF"
                  border="1px solid #FFC857"
                  borderRadius="40px"
                  onClick={handleSortAlphabetically}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "justify-between",
                      gap: "5px",
                    }}
                  >
                    Ordre alphabétique
                    <BsSortAlphaDown
                      style={{ color: "black", fontSize: "16px" }}
                    />
                  </span>
                </CustomButton>
                <ContainSelectData>
                  <InputSelectDate
                    value={filterDates}
                    onChange={(e) =>
                      handleFilterChange("dateDebute", e.target.value)
                    }
                  >
                    <option value="">jj/mm/aa</option>
                    {uniqueDates.map((date, index) => (
                      <option key={index} value={date}>
                        {formatDate(date)}
                      </option>
                    ))}
                  </InputSelectDate>
                  <SpanIcon>
                    <PiCalendarBold className="" />
                  </SpanIcon>
                </ContainSelectData>
                <CustomButton
                  className="d-flex align-items-center gap-1"
                  backgroundColor="#FFF"
                  color="#000000"
                  border="1px solid #FFC857"
                  fontSize="14px"
                  borderRadius="40px"
                  padding={"5.5px 10px"}
                  fontFamily="Roboto"
                  onClick={generatePDF}
                >
                  Extraire par
                  <RiLogoutCircleRLine style={{ fontSize: "18px" }} />
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
        <div className="nombre-de-voyageur">
          <div className="voyagerNomber">
            <h4
              style={{
                color: "#212529",
                fontSize: "40px",
                fontFamily: "Roboto",
              }}
            >
              {reservationsArrivesSelectionne.length}
            </h4>
            <p
              className="m-0 p-0"
              style={{
                color: "#212529",
                fontFamily: "Roboto",
              }}
            >
              Voyageurs sont arrivés le {filterDate}
            </p>
          </div>
        </div>
        <div className="card-body ">
          <table className="table">
            <thead>
              <tr
                className="table-row-red"
                style={{ padding: "10px", backgroundColor: "#DFE0E1" }}
              >
                <th scope="col">Voyageur</th>
                <th scope="col">Arrivée</th>
                <th scope="col">Email</th>
                <th scope="col">Info Pre-Stay</th>
                <th scope="col">Badges</th>
                {/* <th scope="col">Annivaire</th> */}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <Loader />
              ) : sortedReservationsArrivesSelectionne.length > 0 ? (
                sortedReservationsArrivesSelectionne.map(
                  (reservation, index) => (
                    <tr
                      key={index}
                      style={{
                        fontSize: "14px",
                        verticalAlign: "middle",
                        padding: "10px",
                        borderBottom: "1px solid #DEE2E6",
                      }}
                    >
                      <td style={{ border: "none" }}>
                        <div className="d-flex align-items-center gap-2">
                          <InfoPersonnel
                            img={DrapeauSenegal}
                            style={{
                              color: "#667085",
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                            }}
                            alt="Drapeau"
                          />
                          <p
                            style={{ fontWeight: 500 }}
                            className="d-flex flex-column p-0 m-0"
                          >
                            {`${FormateName(
                              reservation.voyageur_details.prenom,
                              5
                            )} ${FormateName(
                              reservation.voyageur_details.nom,
                              5
                            )}`}
                            <span
                              style={{
                                color: "#6C757D",
                                fontSize: "12px",
                                fontFamily: "Roboto",
                                fontWeight: 400,
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              Senegal
                            </span>
                          </p>
                        </div>
                      </td>
                      <td style={{ color: "#667085", border: "none" }}>
                        {formatDate(reservation.date_debut_sejour)}
                      </td>
                      <td style={{ color: "#667085", border: "none" }}>
                        {truncateEmail(reservation.voyageur_details.email)}
                      </td>
                      <td style={{ color: "#667085", border: "none" }}>
                        Lorem ipsum dolor sit amet.
                      </td>
                      <td
                        style={{
                          color: "#FFC857",
                          fontSize: "20px",
                          border: "none",
                        }}
                      >
                        <TfiMedall />
                      </td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    <GrInbox style={{ color: "#DFDFDF", fontSize: "38px" }} />
                    <p
                      style={{
                        color: "#BFBFBF",
                        fontSize: "15.30px",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                        marginTop: "10px",
                      }}
                    >
                      Aucune donnée
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CalendrierduClient;
