import React, { useEffect, useState } from "react"
import HotelChambre from "image/image/chambre.png"
import { HiArrowLongRight } from "react-icons/hi2";
import Line from "image/image/line.png"
import { useNavigate } from "react-router-dom";
import Button from "components/FormulairePreStay/Bouton";
import { useTranslation } from "react-i18next";
import { ContainerAccueil, ContainerText, Transform, Contain,
    Message, ContainImage, Image, Image2, TextUtils, ContainTextImage
 } from "components/FormulairePreStay/StyledFormPreStay";
 import { useParams } from "react-router-dom";
import { useGetHotelierIdQuery } from "actions/hotel/queries";
import { useGetVoyageurReservationsQuery } from "actions/voyageur/query"

function AccueilForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { reservationId, hotelId, voyageurId } = useParams();
    const id = parseInt(hotelId);
    const { data: hotelier } = useGetHotelierIdQuery(id);
    const { data: voyageurData } = useGetVoyageurReservationsQuery(voyageurId);
    const voyageur = voyageurData?.[0]
    // console.log({ voyageur });
    const voyageurById = voyageur?.voyageur_details;
// console.log({ voyageurById });

    const handleClick = () => {
        navigate(`/${reservationId}/hotel/${hotelId}/voyage/${voyageurId}/formulaire`);
    };
    const [dureeSejour, setDureeSejour] = useState(0);
// console.log({ dureeSejour });

useEffect(() => {
    if (voyageur?.date_debut_sejour && voyageur?.date_fin_sejour) {
      const dateDebutSejour = new Date(voyageur.date_debut_sejour);
      const dateFinSejour = new Date(voyageur.date_fin_sejour);
      // Réinitialiser les heures à 0 pour éviter les problèmes de fuseau horaire
      dateDebutSejour.setHours(0, 0, 0, 0);
      dateFinSejour.setHours(0, 0, 0, 0);
      const diffInMs = dateFinSejour - dateDebutSejour;
      // Convertir la différence en jours
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
      setDureeSejour(diffInDays);
    }
  }, [voyageur]);

  return (
    <Contain>
        <ContainerAccueil>
            <ContainerText>
                <Transform>
                {t(`Transformons votre sejour chez ${hotelier?.nom_hotel} en moments inoubliables`)}
                </Transform>
                <ContainTextImage>
                <Image2 src={Line}/>
                <div>
                    <Message>
                    Cher {voyageurById?.prenom} {voyageurById?.nom}, Merci d'avoir choisi {hotelier?.nom_hotel} où nous aurons
                    le plaisir de vous accueillir durant {dureeSejour} jours.
                    </Message>
                <Message>
                Pour faire de votre séjour une
                expérience unique et personnalisée, nous avons préparé un questionnaire afin
                de préparer au mieux votre séjour à la Maison
                </Message>
                </div>
                </ContainTextImage>
                <Button onClick={handleClick} name="Remplir le questionnaire" icon={<HiArrowLongRight />}/>
                <div>
                    <TextUtils>
                    Utilisez le lien suivant si le bouton ci-dessus n'apparaît pas : <br />
                    <a href="https://www.azalai.com/azalai-hotel-dakar" target="_blank" rel="noopener noreferrer">https://www.azalai.com/azalai-hotel-dakar</a>
                    </TextUtils>
                </div>
            </ContainerText>
            <ContainImage>
                <Image src={HotelChambre}/>
            </ContainImage>
        </ContainerAccueil>
    </Contain>
  )
}

export default AccueilForm