import { useState, useEffect } from "react";
import { useGetRoomsQuery } from "actions/reservation/query";
import { useGetHotelQuery } from "actions/hotel/queries";

export const useRooms = () => {
  const [optionsChambres, setOptionsChambres] = useState([]);
  const { data: hotel } = useGetHotelQuery();
  const userId = hotel?.id;
  const { data: chambresData } = useGetRoomsQuery(userId);

  useEffect(() => {
    if (chambresData) {
      setOptionsChambres(chambresData.data);
    }
  }, [chambresData]);

  return { optionsChambres };
};
