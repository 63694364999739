import React, { useEffect } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import Header from "components/GestionEnvoie/Hearder";
import AboutYouForm from "./TabsFormPreStay/AboutYouForm";
import ServicePage from "./TabsFormPreStay/ServicePage";
import Sejour from "./TabsFormPreStay/Sejour";
import Validation from "./TabsFormPreStay/Validation";
import GestionEnvoiProvider from "./GestionEnvoiProvider";
import { useForm, FormProvider } from "react-hook-form";
import ValidForm from "./TabsFormPreStay/ValidForm";
import { useAddFormResponseMutation, useAddServicesMutation } from "actions/service/mutation";
import toast from "react-hot-toast";

const FormPreStay = () => {
  const methods = useForm();
  const { getValues } = methods;
  const { voyageurId } = useParams();
  const [addFormResponse, { isLoading: isFormLoading }] = useAddFormResponseMutation();
  const [addService, { isLoading: isServiceLoading }] = useAddServicesMutation();

  const [searchParams, setSearchParams] = useSearchParams();
  const activeKeyFromUrl = searchParams.get("step") || "01";
  const [activeKey, setActiveKey] = React.useState(activeKeyFromUrl);
  useEffect(() => {
    setSearchParams({ step: activeKey });
  }, [activeKey, setSearchParams]);

  const headerData = [
    { nombre: "01", titre: "A propos de vous" },
    { nombre: "02", titre: "Votre séjour" },
    { nombre: "03", titre: "Les services" },
    { nombre: "04", titre: "Validation" },
  ];

  const onSubmit = async () => {
    const formData = getValues();
    formData.voyageur = parseInt(voyageurId);

    const servicesPayants = formData.servicesPayants;

    const formResponseData = {
      voyageur: formData.voyageur,
      form_type: "pre_stay",
      raison_voyage: formData.raison_voyage,
      type_voyageur: formData.type_voyageur,
      moyen_connaissance: formData.moyen_connaissance,
      frequent_dakar: formData.frequent_dakar,
      date_naissance: formData.date_naissance,
      horaire_arrivee: formData.horaire_arrivee,
      recommandation_interessee: formData.recommandation_interessee,
      souhait_particulier: formData.souhait_particulier,
      guide_transport: formData.guide_transport,
      preferences: formData.preferences,
      email: formData.email,
    };

    try {
      // Submit the form data first
      const formResult = await addFormResponse(formResponseData).unwrap();
      console.log("Form data submitted successfully:", formResult);

      // If servicesPayants exists and has services, submit the services data
      if (servicesPayants && servicesPayants.services && servicesPayants.services.length > 0) {
        const servicesData = servicesPayants.services.map((service) => ({
          ...service,
          voyageur: formData.voyageur
        }));
        const serviceResult = await addService({ services: servicesData, voyageur: formData.voyageur }).unwrap();
        console.log("Services data submitted successfully:", serviceResult);
      }

      toast.success("Données du formulaire soumises avec succès");
      setActiveKey("05");
    } catch (err) {
      toast.error("Error lors de la soumission du formulaire:", err);
      console.error("Error submitting form data:", err);
    }
  };

  const handlePrevious = () => {
    if (parseInt(activeKey) > 1) {
      setActiveKey((prev) => (parseInt(prev) - 1).toString().padStart(2, "0"));
    }
  };

  const handleNext = () => {
    if (parseInt(activeKey) < headerData.length) {
      setActiveKey((prev) => (parseInt(prev) + 1).toString().padStart(2, "0"));
    }
  };

  return (
    <div className="overflow-x-hidden">
      <div className="d-flex flex-column justify-content-center p-3">
        <div className="mt-4">
          <div className="d-flex justify-content-center">
            {headerData.map((data, index) => (
              <div key={index} onClick={() => setActiveKey(data.nombre)}>
                <Header
                  nombre={data.nombre}
                  titre={data.titre}
                  isActive={activeKey === data.nombre}
                />
              </div>
            ))}
          </div>

          <GestionEnvoiProvider>
            <FormProvider {...methods}>
              <form
                className="tab-content my-2"
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                {activeKey === "01" && <AboutYouForm onClick={handleNext} />}
                {activeKey === "02" && (
                  <Sejour
                    handleCancel={handlePrevious}
                    handleClick={handleNext}
                  />
                )}
                {activeKey === "03" && (
                  <ServicePage
                    handleCancel={handlePrevious}
                    onClick={handleNext}
                  />
                )}
                {activeKey === "04" && (
                  <Validation handleCancel={handlePrevious} isLoading={isFormLoading || isServiceLoading} />
                )}
                {activeKey === "05" && (
                  <ValidForm handleCancel={handlePrevious} />
                )}
              </form>
            </FormProvider>
          </GestionEnvoiProvider>
        </div>
      </div>
    </div>
  );
};

export default FormPreStay;
