import React, { useEffect, useState } from "react";
import MenuClientDetail from "components/RelationClientDetail/MenuClientDetails/MenuClientDetail";
import { styledRelationClientDetail } from "./RelationClientDetailCss";
import CardLeft from "components/RelationClientDetail/InfoClient/CardLeft";
import "./Style.css";
import { useParams } from "react-router-dom";
// import {
//   useGetVoyageurByIdQuery,
//   useGetVoyageurReservationsQuery,
// } from "actions/apiSliceRTK";
import styled from "styled-components";
import BreadCrumbs from "components/RelationClientDetail/BreadCrumb";
import { useSelector } from "react-redux";
import { DivBreadCrumb } from "components/RelationClientDetail/InfoClient/InfoClientCSS";
import {
  useGetVoyageurByIdQuery,
  useGetVoyageurReservationsQuery,
} from "actions/voyageur/query";
import Loader from "components/Loader/Loader";

export const Container = styled.div`
  /* padding-top: 10px; */
`;
export const ContPage = styled.div`
  /* padding-top: 10px; */
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const RelationClientDetail = () => {
  const navbarWidth = useSelector(
    (state) => state?.persist?.sidebarWidth.navbarWidth
  );
  const [reservationsClient, setReservationsClient] = useState([]);
  const { id } = useParams();
  const idAsInt = parseInt(id, 10);
  const { data: voyageurReservationsData, isLoading: isLoadingReservations } =
    useGetVoyageurReservationsQuery(idAsInt);
  const { data: client, isLoading } = useGetVoyageurByIdQuery(idAsInt);

  // console.log(client, "client");

  useEffect(() => {
    if (voyageurReservationsData) {
      const reservations = voyageurReservationsData.map((reservation) => ({
        ...reservation,
        voyageur_details: reservation.voyageur_details,
      }));
      setReservationsClient(reservations);
    }
  }, [voyageurReservationsData]);

  if (isLoading || isLoadingReservations) {
    return <Loader />;
  }
  return (
    <div style={styledRelationClientDetail.relationClient}>
      <ContPage>
          <Container className="container-fluid" style={{}}>
            <DivBreadCrumb width={navbarWidth}>
              <BreadCrumbs clientName={`${client?.prenom} ${client?.nom}`} />
            </DivBreadCrumb>
            <Container className="row mt-3 ">
              {client && (
                <>
                  <Container className="col-md-12 col-lg-3">
                    <CardLeft client={client} />
                  </Container>
                  <Container
                    className="col-md-12 col-lg-9 px-2 "
                    style={{ overflow: "hidden" }}
                  >
                    <MenuClientDetail
                      client={client}
                      reservationsClient={reservationsClient}
                    />
                  </Container>
                </>
              )}
            </Container>
          </Container>
        </ContPage>
    </div>
  );
};

export default RelationClientDetail;
