// import React, { useEffect } from "react";
// import { InputValidation } from "../StyledAdmin";
// import { useFormContext } from "react-hook-form";
// import { useParams } from "react-router-dom";
// import { useTranslation } from "react-i18next";

// export default function Validation() {
//   const { register, setValue } = useFormContext();
//   const { id } = useParams(); // Extract the idh from the URL params
//   const { t } = useTranslation();

//   // Set the voyageur value when the component mounts
//   useEffect(() => {
//     if (id) {
//       setValue("voyageur", id);
//       console.log(id);
//     }
//   }, [id, setValue]);

//   return (
//     <div className="container py-5" style={{ paddingLeft: "8rem" }}>
//       <h4 className="text-center text-uppercase mt-5">
//         {t("Envoi du questionnaire")}
//       </h4>
//       <form
//         action=""
//         className="mt-8 d-flex flex-column w-100 align-items-center justify-content-center">
//         <label htmlFor="valide">
//           {t(
//             "Pour valider ce questionnaire, merci de renseigner votre email ici :"
//           )}
//         </label>
//         <InputValidation
//           {...register("email", { required: "Email is required" })}
//           type="text"
//           id="valide"
//           placeholder="Indiquez votre e-mail"
//           className="px-3"
//         />
//       </form>
//       <div className="d-flex align-items-center justify-content-center">
//         <button type="submit" className="btn btn-danger mt-5">
//           Envoyer
//         </button>
//       </div>
//     </div>
//   );
// }

import React from "react";
import styled from "styled-components";
import Titre from "components/FormulairePreStay/Titre";
import Bouton from "components/FormulairePreStay/Bouton";
import Text from "components/FormulairePreStay/Text";
import { useForm, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
// import ValidForm from "./ValidForm";

const Container = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: start;
  flex-direction: column;
  gap: 16px;
`;
const IconContainer = styled.div`
  border-radius: 10px;
  border: 2px solid #00000020;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Input = styled.input`
  border: none;
  width: 100%;
  outline: none;
  box-shadow: none;
  color: #212529;
`;
const ContainButton = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 16px;
`;

function Validation({ isLoading, handleCancel }) {
  const methods = useForm();
  const { setValue } = methods;
  const { register } = useFormContext();
  const { voyageurId } = useParams();
  setValue("voyageur", voyageurId);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <Titre
        text="Envoi du questionnaire"
        style={{
          fontFamily: "Roboto",
          fontSize: "28px",
          fontWeight: 600,
        }}
      />
      <Container>
        <Text
          content={
            "Pour valider ce questionnaire, merci de renseigner votre email ici"
          }
          color={"#555555"}
        />
        <IconContainer>
          <Input placeholder="Indiquer votre e-mail" {...register("email")} />
        </IconContainer>
      </Container>
      <ContainButton>
        <Bouton onClick={handleCancel} type={"button"} name={"Précédent"} />
        <Bouton type="submit" name={isLoading ? "Chargement..." : "Soumettre"} />
      </ContainButton>
      {/* <ValidForm/> */}
    </div>
  );
}

export default Validation;
