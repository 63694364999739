import React, { useState } from "react";
import Text from "components/FormulairePreStay/Text";
import styled from "styled-components";
import BoutonNeomorphisme from "components/FormulairePreStay/BoutonNeomorphisme";
import Bouton from "components/FormulairePreStay/Bouton";
import Titre from "components/FormulairePreStay/Titre";
import { useFormContext } from "react-hook-form"; // Import useFormContext for react-hook-form
import "../color.css";

const Container = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: start;
    flex-direction: column;
`;

const IconContainer = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
`;

const Textarea = styled.textarea`
    border: 1px solid #D9D9D9;
    outline: none;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    padding: 15px;
    margin-bottom: 16px;
    border-radius: 5px;
`;

const ContainButton = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
`;

function Sejour({ handleCancel, handleClick }) {
    const { register, watch } = useFormContext(); // Access form context to use register
    const [isButtonActive, setIsButtonActive] = useState(false);
    const autreDemandeActive = watch("recommandation_interessee") === "autres"; // Watch the "recommandations" field

    const toggleButton = () => {
        setIsButtonActive((prev) => !prev);
    };

    return (
        <div className="d-flex justify-content-center flex-column align-items-center">
            <div className="flex justify-content-center">
                <Titre
                    text="Mieux vous servir"
                    style={{
                        fontFamily: "Roboto",
                        fontSize: "28px",
                        fontWeight: 600,
                    }}
                />
            </div>
            <Container className="col-lg-6 col-sm-12 col-md-6 col-xl-6">
                <Text content={"Êtes-vous intéressé par nos recommandations à propos..."} />
                <IconContainer>
                    <input
                        type="radio"
                        className="custom-radio"
                        name="recommandation_interessee"
                        id="activite"
                        value="activites"
                        {...register("recommandation_interessee")} // Register the input
                    />
                    <label className="labelRadio" htmlFor="activite">Des activités et divertissements environnants ?</label>
                </IconContainer>
                <IconContainer>
                    <input
                        type="radio"
                        className="custom-radio"
                        name="recommandation_interessee"
                        id="restaurant"
                        value="restaurant"
                        {...register("recommandation_interessee")} // Register the input
                    />
                    <label className="labelRadio" htmlFor="restaurant">Des restaurants proches de l'hôtel ?</label>
                </IconContainer>
                <IconContainer>
                    <input
                        type="radio"
                        className="custom-radio"
                        name="recommandation_interessee"
                        id="autres"
                        value="autres"
                        {...register("recommandation_interessee")} // Register the input
                    />
                    <label className="labelRadio" htmlFor="autres">Une autre demande ?</label>
                </IconContainer>

                {/* Conditionally display the text area based on the selected radio button */}
                {autreDemandeActive && (
                    <Textarea
                        rows="4"
                        placeholder="Décrivez votre demande"
                        {...register("recommandation_autres")} // Use a different key for the textarea
                    />
                )}

                <Text content={"Un souhait ou besoin particulier...nous ferons le maximum pour accéder à votre demande"} />
                <IconContainer>
                    <input
                        type="radio"
                        className="custom-radio"
                        name="souhait_particulier"
                        id="chambre"
                        value="chambre"
                        {...register("souhait_particulier")} // Register the input
                    />
                    <label className="labelRadio" htmlFor="chambre">Concernant votre chambre ?</label>
                </IconContainer>
                <IconContainer>
                    <input
                        type="radio"
                        className="custom-radio"
                        name="souhait_particulier"
                        id="sejour"
                        value="sejour"
                        {...register("souhait_particulier")} // Register the input
                    />
                    <label className="labelRadio" htmlFor="sejour">Concernant votre séjour en général ?</label>
                </IconContainer>

                <Text content={"Transports"} />
                <IconContainer>
                    <input
                        type="radio"
                        className="custom-radio"
                        name="guide_transport"
                        id="transport"
                        value={true} // Set value to true for the selected option
                        {...register("guide_transport", { setValueAs: (v) => v === "true" })}
                    />
                    <label className="labelRadio" htmlFor="transport">
                        Avez-vous besoin d’informations pour rejoindre l’hôtel le jour de votre arrivée ?
                    </label>
                </IconContainer>

                <Text content={"Avez-vous des préférences ou des choses à nous faire savoir ?"} />
                <IconContainer>
                    <BoutonNeomorphisme onClick={toggleButton} active={isButtonActive} type="button" />
                    <span>{isButtonActive ? "Oui" : "Non"}</span>
                </IconContainer>

                {isButtonActive && (
                    <Textarea
                        rows="4"
                        placeholder="Vos préférences"
                        {...register("preferences")} // Register preferences text area
                    />
                )}
            </Container>
            <ContainButton>
                <Bouton onClick={handleCancel} name={"Précédent"} />
                <Bouton onClick={handleClick} name={"Suivant"} />
            </ContainButton>
        </div>
    );
}

export default Sejour;
