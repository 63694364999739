import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import hotels from "image/azalai.png";
import { ConnexionBody, ImageLogin, LogoForm, ParaLogoForm } from "./LoginPageStyle";
import { RELATION_CLIENT_PATH } from "routes/navigation/navigationPaths";
import { useFormContext } from "react-hook-form";
import COLORS from "utils/Variables/color";
import { useSelector } from "react-redux";
import { getUserStatus } from "redux/features/userSliceWithTokenValidation";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { FaLock } from "react-icons/fa";
import imageLogin1 from "../../image/ImageLogin1.png";
import imageLogin2 from "../../image/ImageLogin2.png";
import imageLogin3 from "../../image/ImageLogin3.png";
import useLogin from "hooks/UseLogin";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";

const ConnexionForm = () => {
  const images = [imageLogin1, imageLogin2, imageLogin3];
  const { register, handleSubmit, formState, reset } = useFormContext();
  const { handleLogin, isLoading } = useLogin();
  const [remember, setRemember] = useState(false);
  const isAuthentificate = useSelector(getUserStatus);
  const [showPassword, setShowPassword] = useState(false);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return isAuthentificate ? (
    <Navigate to={`/${RELATION_CLIENT_PATH}`} replace />
  ) : (
    <ConnexionBody className="overflow-x-hidden">
      <div className="row ">
        <div className="col-lg-6 col-md-6 col-sm-12 d-md-block d-none m-0 py-0">
          <ImageLogin imageUrl={images[currentImageIndex]} />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6 d-flex flex-column gap-2 px-5 align-items-center justify-content-center">
          <div className="d-inline-flex align-items-center mt-1 gap-2">
            <LogoForm src={hotels} alt="Hotels" className="" />
            <ParaLogoForm>Azalai hotel</ParaLogoForm>
          </div>
          <form
            className="auth-forms"
            // autoComplete="off"
            onSubmit={handleSubmit((data) => handleLogin(data, reset))}
          >
            <div className="d-flex flex-column justify-content-center gap-3 rounded">
              <div style={{ fontSize: "26px", fontWeight: "600" }} className="text-center">Connectez-vous en tant que Hôtelier</div>
              <div>
              <label htmlFor="basic-addon1" className="fw-bold pb-1">E-mail</label>
              <div className="input-group mb-3 border border-black rounded">
                <span className="input-group-text py-3 bg-transparent border border-0" id="basic-addon1">
                  <MdOutlineAlternateEmail />
                </span>
                <input
                  type="text"
                  className="form-control py-3 bg-transparent border border-0"
                  placeholder="Tapez votre e-mail"
                  aria-label="Username"
                  id="basic-addon1"
                  aria-describedby="basic-addon1"
                  {...register("email", { required: true })}
                />
              </div>
              {formState.errors && formState.errors.email && (
                <div className="alert alert-danger gfa-alert-danger" role="alert">
                  {formState.errors.email?.message}
                </div>
              )}
              </div>
              <div>
              <label className="fw-bold pb-1">Mot de passe</label>
              <div className="input-group mb-3 border border-black rounded">
                  <span className="input-group-text py-3 bg-transparent border border-0" id="basic-addon1">
                    <FaLock />
                  </span>
                  <input
                    style={{ outline: "none" }}
                    type={showPassword ? "text" : "password"}
                    className="form-control py-3 bg-transparent border border-0"
                    placeholder="Tapez votre mot de passe"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    {...register("password", { required: true })}
                  />
                   <span className="d-flex align-items-center p-3"
                      onClick={() => setShowPassword(!showPassword)}
                      style={{ cursor: "pointer" }}>
                     {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>
                {formState.errors && formState.errors.password && (
                  <div className="alert alert-danger gfa-alert-danger" role="alert">
                    {formState.errors.password?.message}
                  </div>
                )}
                </div>
              <div className="text-end">
                <Link to="/auth/forgetPassword">
                  <span
                    style={{
                      fontSize: "16px",
                      textDecoration: "none",
                      fontWeight: "600",
                    }}
                  >
                    Mot de passe oublié?
                  </span>
                </Link>
              </div>
              <div className="extra justify-content-between">
                <div className="mb-4">
                  <div className="form-check d-flex align-items-center gap-2 noColumn">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="remember"
                      style={{ width: "24px", height: "24px", border: "2.67px solid #9E9E9E" }}
                      checked={remember}
                      onChange={(e) => setRemember(e.currentTarget.checked)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="remember"
                      style={{
                        color: "rgba(0, 0, 0, 0.87)",
                        fontFamily: "Roboto",
                        fontSize: "18.67px",
                        fontWeight: 400,
                        marginTop: "2px",
                      }}
                    >
                      Gardez-moi connecté
                    </label>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="w-100"
                  style={{
                    backgroundColor: `${COLORS.activeColor}`,
                    borderRadius: "5.333px",
                    padding: "12px 0px",
                    border: "none",
                    fontSize: "21.33px",
                    fontWeight: "500",
                    color: "#000",
                  }}
                >
                  <span>{isLoading ? "Connexion..." : "Se connecter"}</span>
                </button>
              </div>
            </div>
          </form>
          <div></div>
        </div>
      </div>
    </ConnexionBody>
  );
};

export default ConnexionForm;
