import React, { useState } from "react";
import SelectComponent from "./Select";
import { Input, Label } from "./NouveauVoyageurCss";
import InputCalendrier from "./InputCalendrier";
import SelectChambre from "./SelectChambre";
import { useFormContext } from "react-hook-form";
// import InputTexte from "./InputTexte";

export default function FormInfoReservation({ optionsChambres }) {
  const {
    formState: { errors },
    watch,
    register,
  } = useFormContext();
  const chambresWatch = watch("chambres");
  //   const nomReservationWatch = watch("nomReservation");
  //   console.log({ nomReservationWatch });
  const [minEndDate, setMinEndDate] = useState("");

  // Watch the dateDebut value
  const dateDebut = watch("dateDebut");
  React.useEffect(() => {
    if (dateDebut) {
      setMinEndDate(dateDebut); // Set the minimum date for dateFin
    }
  }, [dateDebut]);
  return (
    <div className="row d-flex flex-column align-items-center px-3">
      <div className="row mt-4">
        <div className="d-flex flex-column col-lg-6 col-md-6">
          <InputCalendrier label="Date de début du séjour" name="dateDebut" />
          {errors.dateDebut && (
            <p style={{ color: "red" }}>{errors.dateDebut.message}</p>
          )}
        </div>
        <div className="d-flex flex-column col-lg-6 col-md-6">
          {/* <InputCalendrier
            label="Date de fin du séjour"
            name="dateFin"
            minDate={minEndDate}
          /> */}
          <InputCalendrier label="Date de début du séjour" name="dateFin" minDate={minEndDate} />
          {errors.dateFin && (
            <p style={{ color: "red" }}>{errors.dateFin.message}</p>
          )}
        </div>
      </div>
      <div className="row mt-4">
        <div className="d-flex flex-column col-lg-6 col-md-6">
          <Label className="mb-1">
            Chambre disponible
          </Label>
          <SelectChambre optionsChambres={optionsChambres} />
          {errors.chambres && (
            <p style={{ color: "red" }}>{errors.chambres.message}</p>
          )}
        </div>
        <div className="d-flex flex-column ol-lg-6 col-md-6">
          <Label className="mb-1">Nombre de chambre</Label>
          <Input
            type="text"
            readOnly
            value={chambresWatch ? chambresWatch.length : 0}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="d-flex flex-column col-lg-6 col-md-6">
          <Label className="mb-1">
            Nombre de personne<span style={{ color: "red" }}>*</span>
          </Label>
          <Input type="number" {...register("nombrePerson")} />
          {errors.nombrePerson && (
            <p style={{ color: "red" }}>{errors.nombrePerson.message}</p>
          )}
        </div>
        <div className="d-flex flex-column ol-lg-6 col-md-6">
          <Label className="mb-1">
            Statut de réservation<span style={{ color: "red" }}>*</span>
          </Label>
          <SelectComponent
            name="statut"
            options={[
              { value: "En cours", text: "En cours" },
              { value: "Terminée", text: "Terminée" },
              { value: "Annulée", text: "Annulée" },
            ]}
          />
          {errors.statut && (
            <p style={{ color: "red" }}>{errors.statut.message}</p>
          )}
        </div>
      </div>
      <div className="row mt-4">
        <div className="d-flex flex-column col-lg-6 col-md-6">
          <Label className="mb-1">
            Statut du paiement<span style={{ color: "red" }}>*</span>
          </Label>
          <SelectComponent
            name="paiement"
            options={[
              { value: "Payé", text: "Payé" },
              { value: "En attente", text: "En attente" },
              { value: "Remboursé", text: "Remboursé" },
            ]}
          />
          {errors.paiement && (
            <p style={{ color: "red" }}>{errors.paiement.message}</p>
          )}
        </div>
        <div className="d-flex flex-column col-lg-6 col-md-6">
          <Label className="mb-1">
            Source de Paiement<span style={{ color: "red" }}>*</span>
          </Label>
          <SelectComponent
            name="source"
            options={[
              { value: "OrangeMoney", text: "Orange Money" },
              { value: "Wave", text: "Wave" },
              { value: "Espèce", text: "Espèce" },
              { value: "Carte", text: "Carte" },
            ]}
          />
          {errors.source && (
            <p style={{ color: "red" }}>{errors.source.message}</p>
          )}
        </div>
      </div>
      <div className="row mt-4">
        {/* <div className="d-flex flex-column col-lg-6 col-md-6">
          <InputTexte label="Nom réservation" name="nomReservation" />
          {errors.nomReservation && (
            <p style={{ color: "red" }}>{errors.nomReservation.message}</p>
          )}
        </div> */}
         <div className="d-flex flex-column col-lg-6 col-md-6">
          <Label className="mb-1">Type de client</Label>
          <SelectComponent
            name="typedeClient"
            options={[
              //   { value: "Solo", text: "Solo" },
              { value: "En couple", text: "En couple" },
              { value: "Famille", text: "Famille" },
              { value: "Professionnel", text: "Professionnel" },
            ]}
          />
          {errors.typeDeVoyage && (
            <p style={{ color: "red" }}>{errors.typeDeVoyage.message}</p>
          )}
        </div>
        <div className="d-flex flex-column col-lg-6 col-md-6">
          <Label className="mb-1">
            Origine<span style={{ color: "red" }}>*</span>
          </Label>
          <SelectComponent
            name="origine"
            options={[
              { value: "pms", text: "pms" },
              { value: "manuel", text: "manuel" },
            ]}
          />
          {errors.origine && (
            <p style={{ color: "red" }}>{errors.origine.message}</p>
          )}
        </div>
      </div>
      <div className="row mt-4">
        <div className="d-flex flex-column col-lg-6 col-md-6">
          <Label className="mb-1">Demande spéciale</Label>
          <Input className="" {...register("demandeSpeciale")} />
          {errors.demandeSpeciale && (
            <p style={{ color: "red" }}>{errors.demandeSpeciale.message}</p>
          )}
        </div>
      </div>
    </div>
  );
}
