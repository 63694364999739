import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useGetHotelierIdQuery } from "actions/hotel/queries"

const TextLogo = styled.p`
  font-weight: 700;
  font-size: 20px;
  color: white;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
  @media(min-width: 320px) and (max-width: 425px){
  font-size: 12px;
  }
`

const Logo = ({ src, link }) => {
  const { hotelId } = useParams();
    // const id = parseInt(hotelId);
    const { data: hotelier } = useGetHotelierIdQuery(hotelId);

    return (
      <div>
        <NavLink to={link} className="d-flex align-items-center flex-nowrap gap-2">
          <img src={src} alt="Logo" className="w-100" />
        <TextLogo className="p-0 m-0">{hotelier?.nom_hotel}</TextLogo>
          </NavLink>
      </div>);
};

export default Logo;
