import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { OptionVenez, OptionReserve, OptionConnu } from "components/FormulairePreStay/data";
import Text from "components/FormulairePreStay/Text";
import Titre from "components/FormulairePreStay/Titre";
import BoutonNeomorphisme from "components/FormulairePreStay/BoutonNeomorphisme";
import clockSrc from "image/clock.png";
import calendarSrc from "image/calendar.png";
import Button from "components/FormulairePreStay/Bouton";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";
import {
    Container, IconContainer, Select, CustomInput,
    NeumorphicButtonContainer, ResponseText, Img
} from "components/FormulairePreStay/StyledFormPreStay";

const Propos = ({ onClick }) => {
    const [isButtonActive, setIsButtonActive] = useState(false);
    const toggleButton = () => {
        setIsButtonActive((prev) => !prev);
    };

    const { t } = useTranslation();
    const { register, control, setValue } = useFormContext();

    // Function to format date as YYYY-MM-DD
    const formatDate = (date) => date ? date.toISOString().split("T")[0] : "";

    // Function to format time as HH:mm
    const formatTime = (date) => {
        if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
            console.error("Invalid date:", date);
            return null;
        }
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        return `${hours}:${minutes}:${seconds}`;
    };


    // Monitor whether the 'frequent_dakar' field value changes based on the button click
    const frequentDakarValue = isButtonActive ? true : false;

    return (
        <>
            <div className="flex align-items-center flex-column">
                <Titre
                    text={t("À propos de vous")}
                    style={{
                        fontFamily: "Roboto",
                        fontSize: "28px",
                        fontWeight: 600,
                    }}
                />
                <div className="row">
                    <Container className="col-lg-6 col-sm-12 col-md-12 col-xl-6">
                        <IconContainer>
                            <Select {...register("type_voyageur")}>
                                {OptionVenez.map((item) => (
                                    <option key={item.id} value={item.value}>
                                        {item.name}
                                    </option>
                                ))}
                            </Select>
                        </IconContainer>
                        <IconContainer>
                            <Select {...register("raison_voyage")}>
                                {OptionReserve.map((item) => (
                                    <option key={item.id} value={item.value}>
                                        {item.name}
                                    </option>
                                ))}
                            </Select>
                        </IconContainer>
                        <IconContainer>
                            <Select {...register("moyen_connaissance")}>
                                {OptionConnu.map((item) => (
                                    <option key={item.id} value={item.value}>
                                        {item.name}
                                    </option>
                                ))}
                            </Select>
                        </IconContainer>
                        <NeumorphicButtonContainer>
                            <div>
                                <Text color="#555555" size={"16px"} content="Avez-vous l'habitude de venir à Dakar ?" />
                            </div>
                            <BoutonNeomorphisme onClick={toggleButton} active={isButtonActive} type="button" />
                            <ResponseText>{isButtonActive ? "Oui" : "Non"}</ResponseText>
                            {/* Register the frequent_dakar field based on the button toggle */}
                            <input
                                type="hidden"
                                {...register("frequent_dakar")}
                                value={frequentDakarValue}
                            />
                        </NeumorphicButtonContainer>
                        <IconContainer>
                            <div className="my-unique-datepicker">
                                <Controller
                                    control={control}
                                    name="date_naissance"
                                    render={({ field }) => (
                                        <DatePicker
                                            selected={field.value}
                                            onChange={(date) => setValue("date_naissance", formatDate(date))}
                                            placeholderText="Indiquez votre date de naissance"
                                            customInput={<CustomInput />}
                                            dateFormat="yyyy-MM-dd" // Set date format to YYYY-MM-DD
                                        />
                                    )}
                                />
                            </div>
                            <Img src={calendarSrc} />
                        </IconContainer>
                        <IconContainer>
                            <div className="my-unique-datepicker">
                                <Controller
                                    control={control}
                                    name="horaire_arrivee"
                                    render={({ field }) => (
                                        <DatePicker
                                            selected={field.value ? new Date(`1970-01-01T${field.value}`) : null}
                                            onChange={(date) => {
                                                if (date) {
                                                    const formattedTime = formatTime(date);
                                                    if (formattedTime) {
                                                        field.onChange(formattedTime);
                                                    }
                                                } else {
                                                    field.onChange(null);
                                                }
                                            }}
                                            placeholderText="Indiquez votre horaire d'arrivée estimée"
                                            customInput={<CustomInput />}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={1}
                                            timeCaption="Time"
                                            dateFormat="HH:mm:ss"
                                        />
                                    )}
                                />
                            </div>
                            <Img src={clockSrc} />
                        </IconContainer>
                        <Button onClick={onClick} name="Suivant" />
                    </Container>
                </div>
            </div>
        </>
    );
};

export default Propos;
