import React, { useState } from "react";
import { HiOutlineArrowPath } from "react-icons/hi2";
import { MdOutlineFileDownload } from "react-icons/md";
import { PiCalendarBold } from "react-icons/pi";
import "../../../../components/CustomComponent/Calendar/calendrier.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  DETAIL_RESERVATION_PATH,
  NOUVELLE_RESERVATION_PATH,
  UPDATE_RESERVATION_PATH,
} from "routes/navigation/navigationPaths";
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss";
import {
  ContainerDemandeClient, InputSelectDate,
  ContainSelectData, SpanIcon
} from "components/RelationClientDetail/DemandeClient/DemandeClientCSS";
import ImportModal from "../GestionVoyageur/ImportModal";
import {
  CadreHeader,
  CadreHeader2,
  CadreTab,
  GrpBtn,
  Navig,
  Select,
  TextBut,
} from "../GestionVoyageur/GestionVoyageurCss";
import { RiDeleteBinLine } from "react-icons/ri";
import { useSelector } from "react-redux";
// import {
//   useAddNewReservationMutation,
//   useGetReservationsQuery,
//   useDeleteReservationMutation,
// } from "actions/apiSliceRTK";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";
import {
  SpanStatue1,
  SpanStatue2,
  SpanStatue3,
} from "pages/DetailsReservation/DetailsReservation";
import { GoDotFill } from "react-icons/go";
import { Pagination } from "utils/Pagination";
import * as XLSX from "xlsx";
import BreadcumbAdmin from "../GestionVoyageur/BreadcumpAdmin/BreadcumbAdmin";
import fusion from "image/reporting.svg";
import { FiEdit } from "react-icons/fi";
import { FaEye } from "react-icons/fa6";
import CustomButton from "components/CustomComponent/Boutton/CustomButton";
import { PiPlusLight } from "react-icons/pi"
import InputSearch from "components/CustomComponent/SearchInput/InputSearch";
import { useGetReservationsQuery } from "actions/reservation/query";
import { GrInbox } from "react-icons/gr";
import { useAddNewReservationMutation, useDeleteReservationMutation } from "actions/reservation/mutation";
import Modal from "components/CustomComponent/Modal";
import { truncateText } from "utils/constants";
import Loader from "components/Loader/Loader";

const GestionReservation = () => {
  const userId = useSelector((state) => state?.persist?.auth.user_id);
  const { data: reservationsData, refetch, isLoading: LoadingReservations } = useGetReservationsQuery(userId, {
    pollingInterval: 3000, // Rafraîchir toutes les 10 secondes
  });
  const reservations = reservationsData?.data || [];
  const [deleteReservation, { isLoading }] = useDeleteReservationMutation();
  const statusOptions = ["En cours", "Terminée", "Annulée"];
  const originS = [
    {
      id: 1,
      value: "manuel",
      nom: "MANUEL",
    },
    {
      id: 2,
      value: "pms",
      nom: "PMS",
    }
  ]
  const [searchParams, setSearchParams] = useSearchParams();
  const filterDateDebut = searchParams.get("dateDebut") || "";
  const filterDateFin = searchParams.get("dateFin") || "";
  const filterOrigin = searchParams.get("origin") || "";
  const filterStatus = searchParams.get("statut") || "";
  const search = searchParams.get("search") || "";
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showTableBody, setShowTableBody] = useState(false);
  const sortedReservations = [...reservations].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );
  const filteredReservation = sortedReservations.filter((item) => {
    return (
      (!search || item.nom.toLowerCase().includes(search.toLowerCase())) &&
      (!filterDateDebut || item.date_debut_sejour.toLowerCase().includes(filterDateDebut.toLowerCase())) &&
      (!filterDateFin || item.date_fin_sejour === filterDateFin) &&
      (!filterOrigin || item.origin.toLowerCase().includes(filterOrigin.toLowerCase())) &&
      (!filterStatus || item.statut_reservation.toLowerCase().includes(filterStatus.toLowerCase()))
    );
  });

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const toggleTableBody = () => {
    setShowTableBody(!showTableBody);
  };
  // Id hotelier connecté
  const [selectedReservationId, setSelectedReservationId] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);
  const openModale = (reservationId) => {
    setSelectedReservationId(reservationId); // Stocke l'ID sélectionné
    setModalOpened(true); // Ouvre le modal
  }
  const closeModale = () => {
    setModalOpened(false);
  };
  const handleDelete = async () => {
    if (selectedReservationId) {
      try {
        await deleteReservation(selectedReservationId).unwrap();
        refetch();
        closeModale();
        toast.success("Réservation supprimée avec succès");
        // console.log("Réservation supprimée avec succès", data);
      } catch (error) {
        toast.error("Erreur lors de la suppression:");
        closeModale();
        // console.error("Erreur lors de la suppression:", error);
      }
    }
  };
  const navigate = useNavigate();
  const handleDetails = async (reservationId) => {
    navigate(`/${DETAIL_RESERVATION_PATH}/${reservationId}`);
  };
  const handleEdite = async (reservation) => {
    navigate(`/${UPDATE_RESERVATION_PATH}/${reservation.id}`);
  };

  // Fonction pour formater la date
  const formatDate = (dateString) => {
    return format(new Date(dateString), "dd/MM/yyyy");
  };

  const page = parseInt(searchParams.get("page")) || 1;
  const limit = parseInt(searchParams.get("limit")) || 5;
  const startIndex = (page - 1) * limit;
  const endIndex = startIndex + limit;
  const paginatedHotels = filteredReservation.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredReservation.length / limit);
  const handlePageChange = (newPage) => {
    handleFilterChange("page", newPage);
  };
  const handleFilterChange = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set(key, value);
    } else {
      newSearchParams.delete(key);
    }
    setSearchParams(newSearchParams);
  }

  // IMPORTATION RESERVATION PAR EXCEL :
  const parseExcelOrCSV = (fileData) => {
    const parsedData = [];
    const workbook = XLSX.read(fileData, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    data.forEach((row) => {
      const [
        voyageur,
        prenom,
        nom,
        email,
        telephone,
        date_debut_sejour,
        date_fin_sejour,
        chambres_associes,
        nombre_person,
        status_paiement,
        demandes_speciales,
        origin,
        // nom_reservation,
      ] = row;
      parsedData.push({
        voyageur,
        prenom,
        nom,
        email,
        telephone,
        date_debut_sejour,
        date_fin_sejour,
        chambres_associes,
        nombre_person,
        status_paiement,
        demandes_speciales,
        origin,
        // nom_reservation,
        user: userId,
      });
    });
    return parsedData;
  };
  const [addNewReservationMutation] = useAddNewReservationMutation();
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = async (event) => {
      const fileData = event.target.result;
      const parsedData = parseExcelOrCSV(fileData);
      parsedData.forEach(async (reservationData) => {
        try {
          /* const data =  */await addNewReservationMutation(reservationData);
          refetch();
          toast.success("Reservation ajout�e avec succes!");
          // console.log("Réservation ajouté avec succes!", data);
          onRequestClose();
        } catch (error) {
          toast.error("Erreur lors de l'ajout de la r�servation");
          console.error("Erreur lors de l'ajout de la r�servation :", error);
        }
      });
    };
    // Vérifiez le type de fichier et choisissez la méthode de lecture appropriée
    if (file.type === "csv" || file.name.endsWith(".csv")) {
      reader.readAsText(file);
    } else if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel" ||
      file.name.endsWith(".xlsx")
    ) {
      reader.readAsBinaryString(file);
    } else {
      toast.error("Type de fichier non pris en charge.");
    }
  };
  if (LoadingReservations) return <Loader/>;

  return (
    <div className="" style={styledRelationClientDetail.relationClient}>
      <Modal opened={modalOpened} onClosed={closeModale}
    title={"Confirmation"} confirme={handleDelete} cancel={closeModale}>
      <p>Êtes-vous sûr de vouloir supprimer cette réservation ?</p>
      </Modal>
      <div
        style={{
          padding: "20px 40px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}>
        <BreadcumbAdmin label={"Gestion des réservations"} />
        <ContainerDemandeClient
          className="container-fluid"
          style={{
            backgroundColor: "white",
            bordeRadius: "20px",
            padding: "40px",
          }}>
          <CadreHeader>
            <Navig>
              <p
                style={{
                  color: "#000000",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "5px",
                }}>
                <span
                  style={{
                    color: "#008DF3",
                    fontFamily: "Roboto",
                    fontWeight: "400",
                  }}>
                  Admin
                </span>
                <span>
                  / Réservation{" "}
                  <span style={{ color: "gray" }}>
                    {reservations.length}
                  </span>
                </span>
              </p>
            </Navig>
            <InputSearch
              classNSearchInput="border border-warning"
              classInput="class-input"
              value={search}
              onChange={(e) => handleFilterChange("search", e.target.value)}
              placeholder="Rechercher une réservation"
              style={{ width: "200px" }}
            />
            <GrpBtn>
              <Link onClick={toggleTableBody}>
                <CustomButton
                  className="d-flex align-items-center gap-1"
                  borderRadius="30px"
                  border="1px solid #FFC857"
                  color={"#FFC857"}
                  padding={"8px 16px"}
                  backgroundColor="white"
                  fontSize={"18px"}>
                  <HiOutlineArrowPath />
                  <TextBut className="p-0">Synchronisation</TextBut>
                </CustomButton>
              </Link>
              <Link onClick={openModal}>
                <CustomButton
                  className="d-flex align-items-center gap-1"
                  borderRadius="30px"
                  color={"#FFC857"}
                  border="1px solid #FFC857"
                  backgroundColor="white"
                  padding={"8px 16px"}
                  fontSize={"18px"}>
                  <MdOutlineFileDownload />
                  <TextBut className="p-0">Importer Excel/CSV</TextBut>
                </CustomButton>
              </Link>
              <Link to={`/${NOUVELLE_RESERVATION_PATH}`}>
                <CustomButton
                  className="d-flex align-items-center gap-1"
                  borderRadius="30px"
                  color={"black"}
                  border="1px solid #FFC857"
                  backgroundColor="#FFC857"
                  padding={"8px 16px"}
                  fontSize={"18px"}>
                  <PiPlusLight style={{ color: "#000000" }} />
                  <TextBut style={{ color: "#000000" }} className="p-0">
                    Nouvelle réservation
                  </TextBut>
                </CustomButton>
              </Link>
            </GrpBtn>
          </CadreHeader>
          <CadreHeader2>
            <div className="d-flex justify-content-between w-100 align-items-center p-1">
            <div className="d-flex gap-3 flex-wrap">
                <div
                  style={{
                    fontWeight: "500",
                    color: "#000000",
                    fontFamily: "Roboto",
                    lineHeight: "32px",
                  }}>
                  Filtrer par
                </div>
                <ContainSelectData>
                  <InputSelectDate
                    value={filterDateDebut}
                    onChange={(e) => handleFilterChange("dateDebut", e.target.value)}
                  >
                    <option value="">Date début de séjour</option>
                    {reservations.map((item) => (
                      <option key={item.id} value={item.date_debut_sejour}>
                        {formatDate(item.date_debut_sejour)}
                      </option>
                    ))}
                  </InputSelectDate>
                  <SpanIcon>
                    <PiCalendarBold className="" />
                  </SpanIcon>
                </ContainSelectData>
                <ContainSelectData>
                  <InputSelectDate
                    value={filterDateFin}
                    onChange={(e) => handleFilterChange("dateFin", e.target.value)}
                  >
                    <option value="">Date fin de séjour</option>
                    {reservations.map((item) => (
                      <option key={item.id} value={item.date_fin_sejour}>
                        {formatDate(item.date_fin_sejour)}
                      </option>
                    ))}
                  </InputSelectDate>
                  <SpanIcon>
                    <PiCalendarBold className="" />
                  </SpanIcon>
                </ContainSelectData>
                <Select
                  value={filterOrigin}
                  onChange={(e) => handleFilterChange("origin", e.target.value)}
                  style={{
                    border: "1px solid #FFC857",
                    backgroundColor: "white",
                    fontFamily: "Montserrat",
                  }}>
                  <option value="">Origin</option>
                  {originS.map((status, index) => (
                    <option key={index} value={status.value}>
                      {status.nom}
                    </option>
                  ))}
                </Select>
                <Select
                  value={filterStatus}
                  onChange={(e) => handleFilterChange("statut", e.target.value)}
                  style={{
                    border: "1px solid #FFC857",
                    backgroundColor: "white",
                  }}>
                  <option value="">Status</option>
                  {statusOptions.map((status, index) => (
                    <option key={index} value={status}>
                      {status}
                    </option>
                  ))}
                </Select>
              </div>
              <button style={{}} className="m-0 p-0 border-0 bg-white">
                {/* <span style={{ color: "#000000", fontFamily: "Montserrat", fontWeight: 400 }}>Fusionner les profils</span> */}
                <img src={fusion} style={{ width: "120px" }} />
              </button>
            </div>
            {/* <ButtonAll onClick={()=>handleDeleteFiltre()}>Effacer les filtres</ButtonAll> */}
          </CadreHeader2>
          {
            paginatedHotels.length > 0 ? (
              <div className="">
                <CadreTab className="overflow-x-scroll">
                  <table
                    className="table mt-4 table table-hoverd"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Roboto",
                      verticalAlign: "middle",
                    }}>
                    <thead className="table-light table" style={{ color: "#555555" }}>
                      <tr>
                        <th style={{ paddingLeft: "20px" }}>
                          {" "}
                          <input type="checkbox" />
                        </th>
                        <th>Numéro Réservation</th>
                        <th>Prénom et nom</th>
                        <th>Date début séjour</th>
                        <th>Date fin séjour</th>
                        <th>Origine</th>
                        <th>Paiement</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedHotels.map((reservation, index) => (
                        <tr key={index} className="cursor-pointer" onClick={() => handleDetails(reservation.id)}>
                          <td style={{ paddingLeft: "36px", padding: "20px" }} onClick={(e) => e.stopPropagation()}>
                            <input type="checkbox" />
                          </td>
                          <td className="fw-bold" style={{ color: "#101828" }}>
                            {reservation.id}
                          </td>
                          <td className="fw-bold" style={{ color: "#101828" }}>
                            {truncateText(reservation?.voyageur_details?.prenom)}{" "}
                            {truncateText(reservation?.voyageur_details?.nom)}
                          </td>
                          <td style={{ color: "#667085" }}>
                            {formatDate(reservation.date_debut_sejour)}
                          </td>
                          <td style={{ color: "#667085" }}>
                            {formatDate(reservation.date_fin_sejour)}
                          </td>
                          <td style={{ color: "#667085" }}>
                            {reservation.origin.toUpperCase()}
                          </td>
                          <td style={{ color: "#667085" }}>
                            {reservation.status_paiement.toUpperCase()}
                          </td>
                          <td>
                            {reservation.statut_reservation === "En cours" && (
                              <SpanStatue1>
                                {" "}
                                <GoDotFill /> {reservation.statut_reservation}
                              </SpanStatue1>
                            )}
                            {reservation.statut_reservation === "Terminée" && (
                              <SpanStatue2>
                                {" "}
                                <GoDotFill /> {reservation.statut_reservation}
                              </SpanStatue2>
                            )}
                            {reservation.statut_reservation === "Annulée" && (
                              <SpanStatue3>
                                {" "}
                                <GoDotFill /> {reservation.statut_reservation}
                              </SpanStatue3>
                            )}
                          </td>
                          <td onClick={(e) => e.stopPropagation()}>
                            <div className="d-flex align-items-center justify-content-evenly gap-1">
                              <FaEye
                                style={{ color: "gray", cursor: "pointer" }}
                                onClick={() => handleDetails(reservation.id)}
                              />
                              <RiDeleteBinLine
                                style={{ color: "#D92D20", cursor: "pointer" }}
                                disabled={isLoading}
                                onClick={() => openModale(reservation.id)}
                              />
                              <FiEdit
                                style={{ color: "#0078D7", cursor: "pointer" }}
                                onClick={() => handleEdite(reservation)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CadreTab>
                <Pagination totalPages={totalPages} currentPage={page} onPageChange={handlePageChange} />
              </div>
            ) : (
              <>
                <table
                  className="table mt-4 table table-hoverd"
                  style={{
                    fontSize: "14px",
                    fontFamily: "Roboto",
                    verticalAlign: "middle",
                  }}>
                  <thead className="table-light table" style={{ color: "#555555" }}>
                    <tr>
                      <th style={{ paddingLeft: "20px" }}>
                        {" "}
                        <input type="checkbox" />
                      </th>
                      <th>N° Réservation</th>
                      <th>Prénom et nom</th>
                      <th>Date début séjour</th>
                      <th>Date fin séjour</th>
                      <th>Origine</th>
                      <th>Paiement</th>
                      <th>Statut</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                </table>
                <div className="p-5">
                  <div className="p-5 d-flex flex-column align-items-center">
                    <GrInbox style={{ color: "#DFDFDF", fontSize: "38px" }} />
                    <p
                      style={{
                        color: "#BFBFBF",
                        fontSize: "15.30px",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                        marginTop: "10px",
                      }}>
                      Aucune donnée
                    </p>
                  </div>
                </div>
              </>
            )
          }
          <ToastContainer className="mt-5" />
          <ImportModal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            handleFileChange={handleFileChange}
          />
        </ContainerDemandeClient>
      </div>
    </div>
  );
};
export default GestionReservation;
