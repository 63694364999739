import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { ContainerDemandeClient } from "components/RelationClientDetail/DemandeClient/DemandeClientCSS";
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss";
import { GESTION_VOYAGEUR_PATH } from "routes/navigation/navigationPaths";
import BreadcumbAdmin from "pages/Admin/DashboardAdmin/GestionVoyageur/BreadcumpAdmin/BreadcumbAdmin";
import { Navig } from "pages/Admin/DashboardAdmin/GestionVoyageur/GestionVoyageurCss";
import FormInfoVoyageur from "./FormInfoVoyageur";
import FormInfoReservation from "./FormInfoReservation";
import ActionButtons from "./ActionButtons";
import { useVoyageurReservation } from "hooks/useVoyageurReservation";
import { useRooms } from "hooks/useRooms";
import { Div } from "./NouveauVoyageurCss";
import VoyageurSelector from "./VoyageurSelector";

export default function NouveauVoyageur() {
  const { id } = useParams();
  const isUpdate = !!id;
  const navigate = useNavigate();
  const { handleSubmit } = useFormContext();
  // const [loading, setLoading] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(null);

  const { handleSubmitVoyageurReservation, reservation, isLoading } = useVoyageurReservation(id);
  const { optionsChambres } = useRooms();

  const handleButtonClick = (buttonType) => {
    setButtonClicked(buttonType);
  };

  const handleSubmitWithButtons = async () => {
    // setLoading(true);
    try {
      await handleSubmitVoyageurReservation();
      if (buttonClicked === "saveAndExit") {
        setTimeout(() => {
          navigate(`/${GESTION_VOYAGEUR_PATH}`);
        }, 1000);
      }
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      // setLoading(false);
    }
  };

  return (
    <div style={styledRelationClientDetail.relationClient}>
      <div style={{
        padding: "20px 40px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}>
        <BreadcumbAdmin />
        <ContainerDemandeClient className="container-fluid" style={{
          backgroundColor: "white",
          borderRadius: "20px",
          padding: "40px",
        }}>
          <Navig>
            <p style={{
              color: "#000000",
              fontFamily: "Roboto",
              fontWeight: 400,
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: "5px",
            }}>
              <span style={{
                color: "#008DF3",
                fontFamily: "Roboto",
                fontWeight: "400",
              }}>
                {isUpdate && reservation ? "Modification voyageur" : "Nouveau voyageur"}
              </span>
              <span>/</span>
            </p>
          </Navig>
          <form onSubmit={handleSubmit(handleSubmitWithButtons)}>
            <div className="row">
              <div className="col-md-12 col-lg-7">
                <div className="row card border-1 border-thin pb-4 mb-5">
                  <Div className="col-5 col-lg-5">INFORMATIONS DU VOYAGEUR</Div>
                  {/* Add form fields for voyageur information */}
                  <FormInfoVoyageur />
                </div>
                <div className="row card border-1 border-thin pb-5">
                  <Div className="col-6 col-lg-6">INFORMATIONS DE LA RESERVATION</Div>
                  {/* Add form fields for reservation information */}
                  <FormInfoReservation optionsChambres={optionsChambres} />
                </div>
              </div>
              <div className="col-md-12 col-lg-5">
                {!isUpdate && <VoyageurSelector />}
              </div>
            </div>
            <ActionButtons
              loading={isLoading}
              isUpdate={isUpdate}
              onButtonClick={handleButtonClick}
            />
          </form>
        </ContainerDemandeClient>
      </div>
    </div>
  );
}