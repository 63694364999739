/* eslint-disable react/react-in-jsx-scope */
// import HomePage from "pages/Home/HomePage";
import {
  // ADMIN_PATH,
  GESTION_PATH,
  MARKETING_PATH,
  PLUS,
  RELATION_CLIENT_PATH,
  STATISTIQUE,
  DETAIL_CLIENT,
  // DASHBOARD_ADMIN_PATH,
  TEXTESPREPARES,
  TEXTESPREPARES_ALL,
  TEXTESPREPARES_MESSAGES,
  TEXTESPREPARES_FORMULAIRE,
  ELEMENT_TRAITE,
  DEMANDE_CLIENT_PATH,
  TEXTESPREPARES_NEW,
  GESTION_VOYAGEUR_PATH,
  NOUVEAU_VOYAGEUR_PATH,
  GESTION_RESERVATION_PATH,
  NOUVELLE_RESERVATION_PATH,
  DASHBOARD_RELATION_PATH,
  DETAIL_RESERVATION_PATH,
  UPDATE_RESERVATION_PATH,
  UPDATE_VOYAGEUR_PATH,
  UPDATE_EMAIL_PATH,
  FORMULAIRE_PRE_STAY_PATH,
  // TEXTESPREPARES_SEJOUR_PATH,
  // TEXTESPREPARES_BEFORE_PATH,
  // TEXTESPREPARES_AFTER_PATH,
  DETAIL_TEXTESPREPARES_PATH,
  HOTEL_RESERVATION_PATH,
  ACCEUIL_PATH,
} from "../navigationPaths";
import RequireAuth from "layouts/RequireAuth";
// import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import SplitShell from "layouts/AppShells/SplitShell";
import RelationClient from "pages/RelationClient/RelationClient";
import Marketing from "pages/Marketing/Marketing";
// import Admin from "pages/Admin/Admin";
import DashboardRelation from "pages/RelationClient/DashboardRelationClient/DashboardRelation";
import GestionClient from "pages/RelationClient/GEstionClient/GestionClientt";
import Statistique from "pages/RelationClient/Statististique/Statistique";
import Plus from "pages/RelationClient/Plus/Plus";
import RelationClientDetail from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetail";
import ElementTraite from "components/ElementTraite/ElementTraite";
import DemandeClient from "pages/RelationClient/DashboardRelationClient/DemandeClient/DemandeClient";
// import DashboardAdmin from "pages/Admin/DashboardAdmin/DashboardAdmin";
// import Admin from "pages/Admin/Admin";
import NouvelleEmail from "components/AdminTextPrepares/NouveauTextPrepare/NouvelleEmail";
import TableEmailTextPrepares from "components/AdminTextPrepares/ATextesPrepares/TableEmailTextPrepares";
// import NouveauVoyageur from "components/AdminContain/NouveauVoyageur/NouveauVoyageur";

import TextesPrepares from "pages/Admin/DashboardAdmin/TextesPrepares/TextesPrepares";
import GestionVoyageur from "pages/Admin/DashboardAdmin/GestionVoyageur/GestionVoyageur";
import GestionReservation from "pages/Admin/DashboardAdmin/GestionReservation/GestionReservation";
// import ConnexionFormResetMdp from "components/ConnexionFormResetMdp/ConnexionFormResetMdp";
import NouveauVoyageurPage from "pages/Admin/DashboardAdmin/GestionVoyageur/NouveauVoyageur/NouveauVoyageurPage";
import NouvelleReservationPage from "pages/Admin/DashboardAdmin/GestionReservation/NouveauReservation/NouvelleReservationPage";
import DetailsReservation from "pages/DetailsReservation/DetailsReservation";
import DetailTextPrep from "pages/Admin/DashboardAdmin/TextesPrepares/DetailTextPrep";
import FormPreStay from "pages/Admin/GestionEnvoie/FormPreStay";
import AppProvider from "context/AppProvider";
import { Navigate } from "react-router-dom";
// import ConnexionPage from "pages/connexion/ConnexionPage";

/*
|---------------------------------------------------------------
| Les routes privates
|---------------------------------------------------------------
| Toute page qui necessite une authentification doit etre ici
|
*/

export const privateRoutes = [
  {
    path: "",
    errorElement: "erreur",
    element: <RequireAuth />,
    children: [
      {
        path: "",
        element: (
          <AppProvider>
            <SplitShell />
          </AppProvider>
        ),
        children: [
          {
            path: "*",
            element: <Navigate to={ACCEUIL_PATH} />,
          },
          {
            path: "",
            element: <Navigate to={ACCEUIL_PATH} />,
          },
          {
            path: RELATION_CLIENT_PATH,
            element: <RelationClient />,
            children: [
              {
                path: "",
                element: <DashboardRelation />,
              },
              {
                path: DASHBOARD_RELATION_PATH,
                element: <DashboardRelation />,
              },
              {
                path: `${DETAIL_CLIENT}/:id`,
                element: <RelationClientDetail />,
              },
              {
                path: GESTION_PATH,
                path: ELEMENT_TRAITE,
                element: <ElementTraite />,
              },
              {
                path: `${ELEMENT_TRAITE}/${DEMANDE_CLIENT_PATH}/:id`,
                element: <DemandeClient />,
              },
              {
                path: GESTION_PATH,
                element: <GestionClient />,
              },
              {
                path: `:id/${FORMULAIRE_PRE_STAY_PATH}/:id`,
                element: <FormPreStay />,
              },
              {
                path: FORMULAIRE_PRE_STAY_PATH,
                element: <FormPreStay />,
              },
              {
                path: STATISTIQUE,
                element: <Statistique />,
              },
              {
                path: PLUS,
                element: <Plus />,
              },
            ],
          },
          {
            path: MARKETING_PATH,
            element: <Marketing />,
            breadcrumb: "Marketing",
            children: [
              {
                path: "",
                element: <Marketing />,
              },
              {
                path: "dashboard",
                element: <Marketing />,
              },
            ],
          },
          {
            path: TEXTESPREPARES,
            element: <TextesPrepares />,
            breadcrumb: "Textes Préparés",
            children: [
              {
                path: TEXTESPREPARES_ALL,
                element: <TableEmailTextPrepares />,
              },
              {
                path: TEXTESPREPARES_MESSAGES,
                element: "MESSAGES",
              },
              {
                path: TEXTESPREPARES_FORMULAIRE,
                element: "FORMULAIRE",
              },
              {
                path: TEXTESPREPARES_NEW,
                element: <NouvelleEmail />,
              },
              {
                path: "",
                element: <TableEmailTextPrepares />,
              },
              {
                path: `${UPDATE_EMAIL_PATH}/:id`,
                element: <NouvelleEmail />,
              },
              {
                path: `${DETAIL_TEXTESPREPARES_PATH}/:id`,
                element: <DetailTextPrep />,
              },
            ],
          },
          {
            path: GESTION_VOYAGEUR_PATH,
            element: <GestionVoyageur />,
            breadcrumb: "Gestion Voyageur",
          },
          {
            path: NOUVEAU_VOYAGEUR_PATH,
            element: <NouveauVoyageurPage />,
            breadcrumb: "Nouveau Voyageur",
          },
          {
            path: `${UPDATE_VOYAGEUR_PATH}/:id`,
            breadcrumb: "Nouvelle reservation",
            element: <NouveauVoyageurPage />,
          },
          {
            path: GESTION_RESERVATION_PATH,
            element: <GestionReservation />,
            breadcrumb: "Gestion reservation",
          },
          {
            path: NOUVELLE_RESERVATION_PATH,
            element: <NouvelleReservationPage />,
            breadcrumb: "Nouvelle reservation",
          },
          {
            path: `${DETAIL_RESERVATION_PATH}/:id`,
            breadcrumb: "Nouvelle reservation",
            element: <DetailsReservation />,
          },
          {
            path: `${UPDATE_RESERVATION_PATH}/:id`,
            breadcrumb: "Nouvelle reservation",
            element: <NouvelleReservationPage />,
          },
          {
            path: `${HOTEL_RESERVATION_PATH}/:id`,
            breadcrumb: "Nom de l'hotel",
            element: <GestionReservation />,
          },
        ],
      },
    ],
  },
];
