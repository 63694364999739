import React from "react";
import { Link } from "react-router-dom";
import { GESTION_VOYAGEUR_PATH } from "routes/navigation/navigationPaths";
import CustomButton from "components/CustomComponent/Boutton/CustomButton";
import { TextBut } from "pages/Admin/DashboardAdmin/GestionVoyageur/GestionVoyageurCss";

export default function ActionButtons({ loading, isUpdate, onButtonClick }) {
    return (
        <div className="row d-flex mt-5">
            <div className="d-flex gap-2">
                <CustomButton
                    className="d-flex align-items-center gap-1"
                    disabled={loading}
                    type="submit"
                    name="saveAndExit"
                    onClick={() => onButtonClick("saveAndExit")}
                    borderRadius="30px"
                    color="black"
                    border="1px solid #FFC857"
                    backgroundColor="#FFC857"
                    padding={"8px 16px"}
                    fontSize={"18px"}
                >
                    {
                        loading ? <TextBut>
                        {isUpdate ? "Modifier..." : "Sauvegarder..."}
                    </TextBut> :
                    <TextBut>
                    {isUpdate ? "Modifier" : "Sauvegarder"}
                </TextBut>
                    }
                </CustomButton>
                {/* <CustomButton
                    className="d-flex align-items-center gap-1"
                    name="saveOnly"
                    onClick={() => onButtonClick("saveOnly")}
                    type="submit"
                    borderRadius="30px"
                    color="#FFC857"
                    border="1px solid #FFC857"
                    backgroundColor="#fff"
                    padding={"8px 16px"}
                    fontSize={"18px"}
                >
                    <TextBut>
                        {loading ? "Enregistrement..." : ""}
                        {isUpdate ? "Modifier" : "Sauvegarder"}
                    </TextBut>
                </CustomButton> */}
                <Link to={`/${GESTION_VOYAGEUR_PATH}`}>
                    <CustomButton
                        className="d-flex align-items-center gap-1"
                        borderRadius="30px"
                        color="#FFC857"
                        border="1px solid #FFC857"
                        backgroundColor="#fff"
                        padding={"8px 16px"}
                        fontSize={"18px"}
                    >
                        <TextBut>Annuler</TextBut>
                    </CustomButton>
                </Link>
            </div>
        </div>
    );
}