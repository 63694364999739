import React from "react";
import SelectComponent from "./Select";
import { Input, Label } from "./NouveauVoyageurCss";
import { useFormContext } from "react-hook-form";
// import InputCalendrier from "./InputCalendrier";
import Inputdate from "./Inputdate";
// import Calendrier from "components/CustomComponent/Calendar/Calendrier";

// Composant de formulaire pour les informations du voyageur
export default function FormInfoVoyageur() {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="row d-flex flex-column align-items-center px-3">
      <div className="row mt-4">
        <div className="d-flex flex-column  col-md-6 col-lg-6">
          <Label style={{ fontWeight: "500" }} className="mb-1">
            Sélectionnez le titre<span style={{ color: "red" }}>*</span>
          </Label>
          <SelectComponent
            name="selectedOption"
            options={[
              { value: "Passeport", text: "Passeport" },
              { value: "Carte d’identité", text: "Carte d’identité" },
            ]}
          />
          {errors.selectedOption && (
            <p style={{ color: "red" }}>{errors.selectedOption.message}</p>
          )}
        </div>
        <div className="d-flex flex-column col-lg-6 col-md-6">
          <Label className="mb-1">
            Numéro de document<span style={{ color: "red" }}>*</span>
          </Label>
          <Input type="number" {...register("numero_passport")} />
          {errors.numero_passport && (
            <p style={{ color: "red" }}>{errors.numero_passport.message}</p>
          )}
        </div>
      </div>
      <div className="row mt-4">
        <div className="d-flex flex-column col-lg-6  col-md-6">
          <Label className="mb-1">
            Prénom<span style={{ color: "red" }}>*</span>
          </Label>
          <Input className="" {...register("prenom")} />
          {errors.prenom && (
            <p style={{ color: "red" }}>{errors.prenom.message}</p>
          )}
        </div>
        <div className="d-flex flex-column col-lg-6 col-md-6">
          <Label className="mb-1">
            Nom<span style={{ color: "red" }}>*</span>
          </Label>
          <Input className="" {...register("nom")} />
          {errors.nom && <p style={{ color: "red" }}>{errors.nom.message}</p>}
        </div>
      </div>
      <div className="row mt-4">
        <div className="d-flex flex-column col-lg-6 col-md-6">
          <Label className="mb-1">
            E-mail<span style={{ color: "red" }}>*</span>
          </Label>
          <Input type="email" className="" {...register("email")} />
          {errors.email && (
            <p style={{ color: "red" }}>{errors.email.message}</p>
          )}
        </div>
        <div className="d-flex flex-column col-lg-6 col-md-6">
          <Label className="mb-1">
            Numéro de téléphone<span style={{ color: "red" }}>*</span>
          </Label>
          <Input type="tel" {...register("telephone")} />
          {errors.telephone && (
            <p style={{ color: "red" }}>{errors.telephone.message}</p>
          )}
        </div>
      </div>
      <div className="row mt-4">
        <div className="d-flex flex-column col-lg-6 col-md-6">
          <Label className="mb-1">Adresse</Label>
          <Input className="" {...register("adresse")} />
          {errors.adresse && (
            <p style={{ color: "red" }}>{errors.adresse.message}</p>
          )}
        </div>
        <div className="d-flex flex-column col-lg-6 col-md-6">
        {/* <Calendrier type="date" onChange={(date) => setDateFin(date)} placeholder="Date fin de séjour"
                    className="inputdate-greserv" /> */}
          <Inputdate
            label="Date de naissance"
            // name="dateNaissance"
            borderColor="red"
            // {...register("date_naissance")}
            name="dateNaissance"
          />
          {errors.dateNaissance && (
            <p style={{ color: "red" }}>{errors.dateNaissance.message}</p>
          )}
        </div>
      </div>
      <div className="row mt-4">
        <div className="d-flex flex-column col-lg-6 col-md-6">
          <Label className="mb-1">Langue</Label>
          {/* <Input className="" {...register("langue")} /> */}
          <SelectComponent
            name="genre"
            {...register("langue")}
            options={[
              { value: "Homme", text: "FR" },
              { value: "Femme", text: "EN" },
            ]}
          />
          {errors.langue && (
            <p style={{ color: "red" }}>{errors.langue.message}</p>
          )}
        </div>
        <div className="d-flex flex-column col-lg-6 col-md-6">
          <Label className="mb-1">Genre</Label>
          <SelectComponent
            name="genre"
            options={[
              { value: "Homme", text: "Homme" },
              { value: "Femme", text: "Femme" },
            ]}
          />
          {errors.genre && (
            <p style={{ color: "red" }}>{errors.genre.message}</p>
          )}
        </div>
      </div>
    </div>
  );
}
